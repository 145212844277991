import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import './Navigation.css';

function Navigation() {
	return (
    <Navbar collapseOnSelect expand="sm" bg="light" data-bs-theme="light" sticky="top" fixed="top">
        <Container className="justify-content-center">
            <Navbar.Brand href="#landscape"><img className="logo" src="logo.svg" alt="logo" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="flex-grow-0">
                <Nav>
                    <Nav.Link href="#landscape">Landscape</Nav.Link>
                    <Nav.Link href="#city">City</Nav.Link>
                    <Nav.Link href="#about">About</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
  );
}

export default Navigation;