import React from "react";
import { useState, useEffect } from "react";

import './Gallery.css';
import ResponsivePhotoGrid from "./ResponsivePhotoGrid.js";

function Gallery() {
	const [landscapePhotos, setLandscapePhotos] = useState([]);
	const [cityPhotos, setCityPhotos] = useState([]);
	const dummyPhotoList = [];

    // get information for landscape photos
    useEffect(() => {
    	const abortController = new AbortController();

    	const fetchPhotoInformation = async() => {
    		try {
                const res = await fetch("https://nah-fern.de/api/v1/photo-information/landscape", {
                	signal: abortController.signal
                });
                const data = await res.json();
                setLandscapePhotos(data.message);
    		} catch (error) {
    			if (error.name !== "AbortError") {
    				console.log("non-abort error");
    			}
    		}
    	};

        fetchPhotoInformation();

        return () => abortController.abort();
    }, []);

    // get information for city photos
    useEffect(() => {
        const abortController = new AbortController();

        const fetchPhotoInformation = async() => {
            try {
                const res = await fetch("https://nah-fern.de/api/v1/photo-information/city", {
                    signal: abortController.signal
                });
                const data = await res.json();
                setCityPhotos(data.message);
            } catch (error) {
                if (error.name !== "AbortError") {
                    console.log("non-abort error");
                }
            }
        };

        fetchPhotoInformation();

        return () => abortController.abort();
    }, []);

	return (
		<div className="gallery">
		    <div className="marker" id="landscape"></div>
		    <h3><span className="red">L</span>andscape</h3>
		    <ResponsivePhotoGrid photoList={landscapePhotos} />
		    <div className="marker" id="city"></div>
            <h3><span className="red">C</span>ity</h3>
            <ResponsivePhotoGrid photoList={cityPhotos} />
		</div>
	);
}

export default Gallery;