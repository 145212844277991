import React from "react";
import './Photo.css';

function Photo({photoInformation}) {
    return (
    	<div className="photo-wrapper">
            <img
                src={"https://nah-fern.de/api/v1/single-photo/" + photoInformation.id}
                alt={photoInformation.description}
            />
        </div>
    );
}

export default Photo;