import './About.css';

function About() {
	return (
		<>
		<div className="marker" id="about"></div>
		<h3><span className="red">A</span>bout</h3>
		<p className="intro">
            I built this website to show some of the photos I took during various trips over the last years. In contrast to social media like Instagram, there is no endless stream of new photos uploaded every minute, no “liking”, “following”, or “commenting”, but just a bunch of photos you might like or not. Enjoy!<br />
            <br />
            If you feel for any reason a need to contact me, drop me an e-Mail at info-dontlikespam@nah-fern.de, but remove the minus and everything behind.<br />
            <br />
            <span className="centered">&copy; 2023</span>
		</p>
		</>
	);
}

export default About;