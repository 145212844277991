import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Photo from "./Photo.js";

function ResponsivePhotoGrid(props) {

	const calculateRowCount = () => {
		let rowCount = Math.floor(props.photoList.length / 2);

		if (props.photoList.length % 2 === 0) {
			return rowCount;
		} else {
			// an additional row is only needed if there are photos
			// for a not fully filled row
			return rowCount + 1;
		}
	}

	let rowCount = calculateRowCount();
	let index = 0;

    const buildGrid = () => {
    	return ( renderRows() )
    }

    const renderRows = () => {
    	let rows = [];

    	for (let row = 0; row < rowCount; row++) {
    		rows.push(
    			<Row className="align-items-center">
    			    { renderCols() }
    			</Row>
    		);
    	}

    	return rows;
    }

    const renderCols = () => {
    	let cols = [];

    	for (let col = 0; col < 2; col++) {
    		if (index < props.photoList.length) {
    			cols.push(
    				<Col sm>
    				    <Photo photoInformation={props.photoList[index]} />
    				</Col>
    			);
    		}
    		index++;
    	}

    	return cols;
    }

	return (
		<Container>
		    { buildGrid() }
		</Container>
	);
}

export default ResponsivePhotoGrid;